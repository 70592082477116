<template>
  <div id="root">
    <router-view></router-view>
  </div>
</template>

<script>

export default {

}
</script>

