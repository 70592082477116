import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import router from './router'

createApp(App).use(router)
    .use(OneSignalVuePlugin, {
        appId: "9dafaa25-2bb6-49b0-9df0-70bd57de086b",
        safari_web_id: "web.onesignal.auto.4787ada6-f101-40da-894e-0a68fad84e0f",
        notifyButton: {
          enable: false,
        },
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'navigate'
    })
    .mount('#app')
